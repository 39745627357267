.isoMiddle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1;
}
.notificationIcon {
  background-color: rgb(114, 82, 230);
  height: 8px;
  width: 11px;
  border-radius: 5px;
}
.wwico {
  vertical-align: bottom;
}
.spS {
  flex-shrink: 0 !important;
}
.spS span {
  display: flex;
  align-items: center;
}
.spS a {
  display: flex;
}

.ant-select-auto-complete.ant-select .ant-input {
  /* background-color: #2d3446 !important; */
  color: white !important;
}
.customAutoComplete .ant-input,
.customAutoComplete .ant-input:focus {
  color: white !important;
}
.customAutoComplete .ant-input::placeholder {
  color: white !important;
}
.customAutoComplete .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
.ant-select-dropdown {
  /* width: 400px!important;  */
  background-color: #2d3446 !important;
}
.ant-select-dropdown-menu-item {
  color: white !important;
  background-color: #3c3c52 !important;
}

/********** Add Your Global CSS Here **********/

body {
  height: 100%;
  margin: 0;
  background-color: #151521 !important;
  -webkit-overflow-scrolling: touch;
}

html h1,
html h2,
html h3,
html h4,
html h5,
html h6,
html a,
html p,
html li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
}

html ul {
  -webkit-padding-start: 0px;
  list-style: none;
  margin-bottom: 0;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.scrollbar-track {
  background: rgba(222, 222, 222, 0.15) !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

/********** Add Your Global RTL CSS Here **********/

/* Popover */

html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */

html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */

html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */

html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */

html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */

html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}
html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}
html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}
/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.SocialW h4 {
  color: #1890ff;
}
.SocialW {
  width: 100%;
  font-size: 13px;
  color: #788195;
}
.socialnet {
  margin-top: 1px;
  margin-bottom: 1px;
  color: rgb(120, 129, 149);
  padding: 5px;
}

.round {
  /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  /* Firefox 1-3.6 */
  /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
  border-radius: 10%;
}
.mentions {
  padding: 0px 3px 0px 3px;
  color: #7252e6;
  background-color: #0c0c12;
}
.stickr {
  margin: 3px;
  background-color: #27668652;
  font-size: 11px;
  padding: 2px 3px 0px 3px;
  /* -webkit-border-radius: 5%;
  -moz-border-radius: 5%;
  border-radius: 5%; */
  color: #50aef0;
}
.spS {
  word-wrap: normal;
  display: inline-block;
}
.socialB {
  /* width: 48%; */
  /* background-color: #060d1352; */
  margin-right: 5px;
  margin-bottom: 5px;
}

/* 
#Pane2, #Pane3 {
  display:none!important;
} */
.ant-input-group-addon{
  background-color: #050f14!important;
  border: 1px solid #050f14!important;
}
.ant-card-bordered {
  border: 0px solid #e8e8e8 !important;
}
.isoNotify {
  margin-left: 11px !important;
}
.cardBox {
  color: white;
}
.custom-select {
  width: 120px;
}
.custom-select select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}
.custom-select.select-expanded select {
  width: auto;
}

.purp {
  color: #7253e6;
  font-size: 17px;
}
.intbl {
  font-size: 17px;
  color: #7c5adc;
}
.ant-card {
  color: white !important;
  background-color: #1e1e2d !important;
}
.btactive {
  background-color: #7c5adc !important;
}
.addic {
  font-size: 1.6em;
  color: #7c5adc;
  cursor: pointer;
  position: relative;
  z-index: 888;
}
.rightic {
  float: right;
  font-size: 1.6em;
  color: #7c5adc;
  cursor: pointer;
  position: relative;
  z-index: 888;
}
.btnentry {
  height: 36px !important;
  width: 87px;
  font-size: 1.5em !important;
  margin: 10px !important;
}
.tbuttn1 {
  background-color: #6c50bf;
  margin: 3px;
  border-radius: 1px !important;
  color: white;
}
.chrtool {
  /* text-align: end; */
}
.tbuttn,
button.tbuttn,
#timeframe,
#overlays,
#datalayers,
#oscillators,
#timespan,
#periodsb {
  border: 0px;
  padding: 4px;
  border-radius: 1px !important;
  color: white;
  background-color: #7c5adcd4;
  margin: 3px;
}

#stDivC,
#sizzDivC,
#sizzDivC,
#wsbDivC {
  margin-top: 5px;
}
#strategiesList,
#SymbolSelect,
#SymbolSelect2,
#topStrategies,
#topStrategiesSelect,
#WeightsSelect,
#TargetsSelect,
#WinrateSelect,
#ImpactSelect,
#NumTradesSelect,
#stratperiods {
  margin-right: 10px;
  padding: 6px;
  border-radius: 16px !important;
  background-color: #28273d !important;
  border: 0px;
  color: white;
}
.tbuttn:hover {
  background-color: #7c5adc;
}
.brdrinput {
  border-radius: 15px !important;
}
.ant-select-auto-complete.ant-select .ant-input {
  background: #28263c;
  border-radius: 15px !important;
}
.custom-auto-complete .ant-select-dropdown {
  background-color: darkgrey !important;
}
.custom-auto-complete .ant-select-dropdown .ant-select-item {
  color: white !important;
}
.ant-select-search__field {
  color: white !important;
  text-transform: uppercase;
}
.buttrnd {
  border-radius: 5% !important;
  -webkit-border-radius: 5% !important;
  -moz-border-radius: 5% !important;
}
.goB:hover {
  background-color: #606b8a;
}
.upgradeclr {
  color: '#78819559';
}
.noperm,
.noperm:hover {
  color: rgba(120, 129, 149, 0.268) !important;
  -webkit-text-decoration: dashed !important;
          text-decoration: dashed !important;
}

.pblock {
  background-color: #262c50;
  border-radius: 3px;
  padding: 4px 8px 2px 8px;
}
.introBtn {
  color: white;
  background-color: #7252e6;
  width: 100%;
  height: 40px !important;
  border-radius: 9999999px !important;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1em !important;
}
#dashrw {
  margin-right: -4px !important;
  margin-left: -4px !important;
}
.toggle-group {
  display: inline;
}
.fullWidthTable {
  width: 100% !important;
}
#inputUserName,
#inpuPassword {
  background-color: #f0f0f4a6;
}
.toggle-btn {
  padding: 4px 10px;
  border: 1px solid #212e48 !important;
  background-color: #151521;
  cursor: pointer;
  color: #fff;
  margin-bottom: 5px;
  transition: background-color 0.3s;
}
.toggle-btn:hover {
  color: #fff;
  background-color: #7252e6;
}

.toggle-btn.active {
  background: #212e48;
  border: 1px solid #7252e68a !important;
  color: #fff;
}
.-previous,
.-next {
  max-width: 60px;
}
.dateNews {
  border-radius: 15px !important;
  height: 27px;
}
.ReactTable .-pagination {
  justify-content: center !important;
}
.ReactTable .-pagination .-center {
  flex: none;
}
.ReactTable .-pagination .-pageJump input {
  width: 40px;
  text-align: center;
}
.TApane {
  color: white;
}
.over {
  margin-left: 30px;
  margin-top: 30px;
  width: 150px;
  height: 300px;
  position: absolute;
  z-index: 100;
  border: 1px solid black;
  background-color: black;
  /* opacity: 0.3; */
}
.unusualleader {
  display: none !important;
}
#rr_risk,
#rr_enter,
#rr_RR,
#rr_sl,
#rr_tp,
#rr_buySize,
#rr_Leverage,
#rr_Profit,
#rr_Loss,
#rr_wallet,
#rr_symbol,
#rr_risk2 {
  color: black;
  background-color: #f1f3f6;
  width: 130px;
}
.rrdarkbg input {
  border: 0;
  outline: 0;
  margin: 4px;
}
.rrdarkbg {
  text-transform: uppercase;
}
.pcBadge {
  font-size: 10px;
  text-align: center;
}
.pcBadge:before {
  font-size: 12px;
}
#rr_risk,
#rr_buySize,
#rr_Leverage,
#rr_RR,
#rr_Loss,
#rr_Profit {
  padding: 4px;
  padding-left: 13px;
  padding-right: 10px;
}
.prcStrat {
  color: #8a93c8 !important;
  background-color: #060c13;
  padding: 3px;
  border-radius: 2px;
  margin: 5px;
}
.pcBadge:after {
  position: absolute;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  content: '';
}
.tradeBtn {
  background: green;
  color: white !important;
  min-height: 35px;
  margin: 2px;
  border: none !important;
  font-size: 22px !important;
}
::-webkit-scrollbar-thumb {
  background: #5e428f !important;
}
/* .myTooltipClass{
  background-color:green!important;
} */
#Pane1
  > div
  > div.sc-brqgnP.bdZTKm.isoExampleWrapper
  > div:nth-child(3)
  > div.ant-col.ant-col-xs-6.ant-col-sm-6.ant-col-md-6,
#Pane1
  > div
  > div.sc-brqgnP.bdZTKm.isoExampleWrapper
  > div:nth-child(3)
  > div.ant-col.ant-col-xs-8.ant-col-sm-8.ant-col-md-8,
#Pane1
  > div
  > div.sc-brqgnP.bdZTKm.isoExampleWrapper
  > div:nth-child(3)
  > div.ant-col.ant-col-xs-10.ant-col-sm-10.ant-col-md-10 {
  padding: 1px !important;
}
.customTooltip * {
  color: #4a4a4a;
  font-size: 18px;
}

.customTooltip .introjs-tooltip-title {
  color: #0a41c9;
}

.sigstyle {
  font-size: 18px;
}
.-pagination {
  background: #141d26;
}
.collaps {
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}
.arrfill {
  fill: #46466a;
}
button > svg {
  fill: #7251e6;
}

.childico.true {
  display: none !important;
}
.rt-tr-group:nth-of-type(even) {
  /* background: red; */
}
.rt-tr-group:nth-of-type(odd) {
  background: #1e1e2d;
}
.tradeBtn:hover,
.tradeBtn:focus,
.tradeBtn:active {
  text-decoration: none;
  background: #52c41a;
}

.radioBTN {
  font-size: 22px !important;
  margin-left: 20px;
}

.cancelBTN {
  background: grey;
  color: white !important;
}

.tradeBtn:hover {
  border: none !important;
  background: rgb(16, 177, 16);
}

.ant-layout-sider-children div {
  /* overflow: hidden !important; */
}

.noteInfo {
  margin: 10px;
}

.creditsNum {
  font-weight: bold;
  color: rgb(157 134 237);
}

.introText {
  margin-bottom: 4px;
  padding: 5px;
}
.isoLayoutContentWrapper {
  padding: 0px 0px !important;
}
.fireup {
  color: #00b399 !important;
  background-color: #00b399;
}
.dotx {
  margin-right: 1px;
  height: 7px;
  width: 7px;
  display: inline-block;
}
.firedown {
  color: #ff2f64 !important;
  background-color: #ff2f64;
}

.ion-fireball {
  color: #ff8100 !important;
}

.dayswing {
  cursor: pointer;
  margin-left: 5px;
  padding: 2px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: #f1f3f6;
}

.ant-select {
  display: block;
}

.isoTodoInput {
  background-color: #f1f3f6;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #7ba9ef !important;
  border-color: #7ba9ef !important;
}

.ant-checkbox-checked .ant-checkbox-inner:hover {
  background-color: #bcbec1 !important;
  border-color: #bcbec1 !important;
}

.ant-radio-button-wrapper {
  margin-right: 2px !important;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background-color: #7252e6 !important;
  border-color: #7252e6 !important;
  color: white !important;
}

.ant-radio-group-outline {
  background-color: #f1f3f6;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #bcbec1 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #bcbec1 !important;
}

.pdown {
  padding-bottom: 4px !important;
  margin-bottom: 3px;
  border-bottom: 1px solid #303047;
}
.NOBO {
  border: 0 !important;
}
select {
  border: 1px solid rgb(233, 233, 233);
  /* -moz-appearance: none!important; */
}

.ant-badge:nth-of-type(1n + 50) {
  /* display: none; */

  /* opacity:.3; */
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 20px;
}
div.rt-td.rt-expandable {
  max-width: 10px !important;
}
.nlink {
  color: #8e8ef1;
  font-weight: 500;
}
#filterSt {
  margin: 5px;
}
.lnewss {
  color: #c6b5b5 !important;
}

.nItem {
  background-color: #192126;
  color: white;
  padding: 5px;
  border: 5px solid #192126e3;
}

.apd {
  padding: 2px;
  /* margin-bottom: 2px; */
}

.hrtanim {
  font-size: 15px;
  color: rgb(253, 57, 107);
  fill: #fff;
  animation: animation-button 20000ms infinite;
}

.nshow {
  display: none !important;
}

@keyframes animation-button {
  0% {
    color: red;
  }

  25% {
    color: rgb(234, 0, 255);
  }

  50% {
    color: #1890ff;
  }

  75% {
    color: rgb(204, 62, 173);
  }

  100% {
    color: red;
  }
}

.patico {
  margin-right: 3px;
  font-size: 14px;
  color: #34c38f;
  border-radius: 1px;
  padding: 2px;
}

.icc {
  margin-right: 2px;
}

.unusualBox > span {
  padding-bottom: 4px !important;
}

label {
  text-transform: uppercase;
  padding: 1px;
  color: #b9c2e4;
  font-weight: 800;
  margin-left: 3px;
  vertical-align: top;
  font-size: 12px;
}

.ltikr {
  cursor: pointer;
}

a.isoBadgeLink:hover {
  color: #dddbdb;
}

input {
  border: 1px solid #7252e6;
}

.ScanB {
  float: right;
  background-color: #121d27 !important;
  border-color: #121d27 !important;
  color: #a793ed !important;
}

/* [type*=d] */
input[type^='d'] {
  width: 135px;
}

div.frappe-chart.graphics.graph-focus-margin {
  margin-top: 0px !important;
}

div.graph-stats-container.graph-focus-margin {
  margin-bottom: 0px !important;
}

#DistChart
  > div
  > div.frappe-chart.graphics.graph-focus-margin
  > div.div
  > div
  > div {
  border-radius: 0px;
}
.paddIt {
  padding-top: 8px;
}
.fontNote {
  margin-left: 3px;
  color: black;
  font-size: 12px;
}
h1 {
  margin-bottom: 5px;
}
.tikr {
  margin-right: 3px;
  max-width: 70px;
  text-transform: uppercase;
  margin-bottom: 10px;
  padding-left: 5px;
}

.setupItem {
  background-color: #f1f3f6;
  margin: 4px;
  padding: 10px;
}

#maxAlerts,
#expireIN {
  height: 20px;
  width: 65px !important;
}

.alertTables {
  border: 3px solid #f1f3f6;
}

.slider {
  -webkit-appearance: none;
  width: 63%;
  height: 10px;
  background: #d3d3d3;
  border: 0px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 10px;
  background: rgb(114, 82, 230);
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 15px;
  background: rgb(114, 82, 230);
  cursor: pointer;
}

#lastmin {
  width: 100%;
  background: white;
}

.PUTSs,
.badge-soft-d {
  padding-right: 0.4em;
  padding-left: 0.4em;
  /* border-radius: 10rem; */
  color: #f46a6a;
  background-color: rgba(244, 106, 106, 0.18);
}

.longp,
.shortp {
  cursor: pointer;
}

.CALLSs,
.longp,
.badge-soft-u {
  padding-right: 0.4em;
  padding-left: 0.4em;
  /* border-radius: 10rem; */
  color: #34c38f !important;
  background-color: rgba(52, 195, 143, 0.18);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #2a3042;
  border-color: #7252e6;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #7252e6;
}

.ant-checkbox-inner {
  border: 1px solid #7252e6;
  background-color: #2a3042;
}

.trb {
  position: absolute;
  top: 0px;
  right: 0px;
}

.b-money {
  padding-left: 1px;
  margin-right: 0px;
  padding-right: 0.4em;
  color: #788195;
}

.badge-ticket-success {
  color: #00cb8e;
  background-color: #093342;
  font-weight: 500;
  border-radius: 0;
}

.pset {
  color: #e9e9e9;
  vertical-align: top;
}

.b-exp {
  padding-right: 0.4em;
  padding-left: 0.4em;
  color: #4765ff;
  background-color: #142453;
  font-weight: 500;
}

.bo_ic {
  background-color: #2c4136;
  color: #93d470;
  margin-right: 3px;
}

.shortp {
  padding: 2px 7px 2px 7px;
  color: #ff2f64 !important;
  background-color: #060d13;
}

.sq_gr {
  margin-top: 2px;
  background-color: #30492d;
  color: #81ff62;
}

.sq_ic {
  margin-top: 2px;
  background-color: #442d49;
  color: #fc57c5;
}

.sq_nsf {
  color: #00aaff;
  background-color: #405b7057;
}

.vl_ic {
  background-color: #421414;
  color: #eb1414;
}

.darkHeader {
  padding-left: 5px;
  /* background: #182025; */
}

.co_ic {
  background-color: #50422e;
  color: #ec8e19;
}

.b-exp2 {
  padding-left: 1px;
  color: white;
  font-weight: 500;
  /* padding: 2px; */
}

.badge-ticket-danger {
  color: #ff5666;
  background-color: #30213c;
  font-weight: 500;
  border-radius: 0;
}

.badge-ticket-warning {
  color: #ffc555;
  background-color: #30323a;
  font-weight: 500;
  border-radius: 0;
}

.modeSwitch {
  margin-right: 10px;
  color: rgb(70 70 106);
  vertical-align: top;
}

.ant-modal-confirm .ant-modal-body {
  padding: 10px 10px 10px !important;
}

.ant-modal {
  width: 90% !important;
  /* height: 50%!important; */
}

.ant-modal-content {
  border-radius: 0px !important;
}

i {
  cursor: pointer;
}

.chartAnalysis {
  padding: 2px;
  border: 0px;
}

.chartAnalysis h1,
.ttech h1 {
  font-size: 19px;
  padding: 5px;
  font-weight: 500;
  color: #788195;
  background: #182025;
}

#tchart {
  width: 100%;
  height: 100%;
}
.ReactTable .rt-table {
  overflow-x: overlay;
}
.ant-badge-status-warning {
  background-color: #c350c6;
}

@media screen and (max-device-width: 456px) {
  #Pane1 {
    height: 410px !important;
  }

  .isoLayoutContentWrapper {
    padding: 10px 0px !important;
  }
  .oui1 {
    padding: 7px;
  }
  .-center,
  .select-wrap.-pageSizeOptions {
    font-size: 12px;
  }
  .ReactTable .-pagination {
    flex-wrap: nowrap;
  }
  .pagination-top {
    background-color: #131c24;
  }
}
@media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .topSweepersPop {
    margin-right: 2px;
  }

  /* .Tmode{position: relative;} */
  .wsStatus,
  .sidepane,
  #timetoO,
  #marketState,
  #market-open-close,
  #timeto {
    display: none !important;
  }
}

.log-out {
  cursor: pointer;
}

.wsStatus {
  margin-left: 5px;
}
.sidepane {
  border-color: #5378a5;
  border-bottom: 1px solid rgb(131, 83, 141) !important;
  bottom: 80px;
  left: 0px;
  height: 50px;
  width: 100%;
  border-bottom: 1px solid;
  border-top: 1px solid;
}

.drawer {
  color: white;
}

.UOhelp {
  position: absolute;
  top: 0px;
  right: 9px;
  font-size: 22px;
  margin-right: 0px;
}

.TShelp {
  /* position: absolute;
  bottom: -22px;
  right: 0px; */
  font-size: 22px;
  margin-right: 0px;
}

.drawer h3 {
  color: white;
  margin-left: 10px;
}

hr {
  /* background-color: rgb(114, 82, 230);
  height: 1px; */
  border: 0px;
  width: 98%;
}

.liner {
  margin-top: 10px;
  font-size: 13px;
  color: #8b91a0;
  text-align: center;
}

.dlinks,
.tese {
  padding: 8px;
}

.drawerLink {
  font-size: 13px;
  color: #8b91a0;
  text-align: center;
  text-transform: uppercase;
}

.badgeit {
  padding: 3px;
  margin-right: 5px;
  padding-left: 6px;
  padding-right: 6px;
  /* width: 60px; */
  color: #7252e6;
  display: inline;
  background-color: #1b1e24;
  border-radius: 3px;
}

.PUT {
  color: #ff2f64;
  /* font-weight:bold;*/
  letter-spacing: 1px;
  font-size: 14px;
}

.CALL {
  color: #00b399;

  letter-spacing: 1px;
  font-size: 14px;
}

.ant-badge-status-dot.ant-badge-status-default {
  background-color: #896bf6;
  /* border-radius: 50%;
    -webkit-animation: antStatusProcessing 1.2s infinite ease-in-out;
    animation: antStatusProcessing 1.2s infinite ease-in-out; */
}

.ant-badge-status-dot.ant-badge-status-warning {
  background-color: #ff3063;
}

.detek {
  display: block;
  font-size: 15px;
  color: #df8fcd;
  background-color: #060d13;
  padding: 6px 6px 6px 10px;
}

.gp {
  background-color: #896bf6;
}

.co {
  background-color: #3feb41;
}

.rv {
  background-color: #86eb41;
}

.cv {
  background-color: #ff3063;
}

.fl {
  background-color: #fffb30;
}

.accm {
  background-color: rgb(239, 97, 220);
}

.acc {
  background-color: #1890ff;
}

.ant-badge-status-success {
  background-color: #e7f840;
}

.ant-badge-status-processing {
  background-color: #fff72f;
}

.ant-badge-status-processing::after {
  border: 1px solid #fff72f;
}

.ant-badge-status-dot {
  top: -1px;
  display: inline-block;
  width: 8px;
  height: 8px;
  vertical-align: middle;
  border-radius: 50%;
}

.rightIt {
  float: right;
  cursor: pointer;
  font-size: 17px;
}

.ion-ios-paper-outline {
  margin-left: 5px;
  font-size: 20px;
  color: #2d3446;
}

@keyframes hideshow {
  0% {
    opacity: 1;
  }

  15% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.text-1 {
  animation: hideshow 5s 1s ease infinite;
}

.soundAlert {
  width: 130px !important;
  float: right;
  position: absolute !important;
  top: 9px;
  right: 52px;
}

.filterPop {
  float: right;
  position: absolute !important;
  top: 4px;
  right: 12px;
}
.ticons {
  color: rgb(114, 82, 230);
  bottom: 27px;
  position: relative;
  float: right;
  bottom: 27px;
  position: relative;
}

#selectTicker_input {
  text-transform: uppercase;
  font-size: 14px;
}

/* .DayPickerInput-OverlayWrapper{z-index:9000!important;} */
.hindicator {
  font-weight: bold;
}

.filterLab {
  margin: 2px;
  font-weight: bold;
  font-size: 11px;
}

.filterLaba {
  /* margin-top: 10px; */
  width: 60%;
  font-weight: bold;
  font-size: 12px;
}

.hblock {
  display: flex;
}

.hblock.hdiv {
  flex-grow: 0;
  flex-shrink: 0;
  flex: auto;
}

.hblock:nth-of-type(odd) {
  background: #192126;
  padding: 7px;
}

.hblock:nth-of-type(even) {
  background: #1c2a36;
  padding: 7px;
}

.hsrc {
  font-size: 10px;
}
#assets {
  color: white !important;
  margin: 3px;
  padding-left: 6px;
  padding-right: 6px;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2d3446;
  background-image: none;
}
.newsT,
div[class^='newsT'],
div[class*=' newsT'] {
  padding: 0px;
  overflow-y: scroll;
  max-height: 400px;
  width: 100%;
  background-color: #121d27;
}

.Overview {
  margin-top: 5px;
}

.hsumr {
  color: #788195;
}

.hdate {
  width: 80px;
  display: block;
  font-size: 11px;
  color: #df8fcd;
  background-color: #060d13;
  padding: 2px 3px 2px 3px;
}

.hdatept {
  width: 100px;
  display: block;
  font-size: 13px;
  color: #df8fcd;
  background-color: #060d13;
  padding: 2px 3px 2px 3px;
}

.inptSettings {
  height: 30px;
}
.nTh1 {
  padding: 7px;
  font-size: 17px;
  color: rgb(120, 129, 149);
}
#sectors,
#earningsdays,
#marketcap {
  height: 30px;
}
#notificationSelect {
  background-color: #f1f3f6;
  font-weight: 500;
  padding: 2px;
  border-color: #e9e9e9;
  padding: 2px;
  margin-right: 5px;
  float: right;
  font-size: 12px;
  height: 25px;
  /* width: 83px; */
  vertical-align: text-top;
}

.ion-ios-circle-filled {
  color: #ff3064;
}

.DayPickerInput-Overlay {
  position: fixed !important;
  z-index: 9999 !important;
}

.DayPickerInput-OverlayWrapper {
  z-index: 9999 !important;
}

ul.listArr {
  list-style-type: circle;
}

ul.listNest {
  margin-left: 7px;
  list-style-type: square;
}

.Tmode {
  margin-top: 5px;
  position: absolute;
  font-size: 22px;
  color: #7252e6;
  text-align: right;
  right: 0;
}
.flowtext {
  margin-left: 6px;
  text-align: center;
  float: left;
}
.ReactTable .-pagination .-btn {
  color: rgb(250, 238, 98);
}

.PUTS {
  padding: 2px 7px 2px 7px;
  color: white;
  background-color: #ff2f64;
  font-weight: bold;
  cursor: pointer;
}

.CALLS {
  padding: 2px 7px 2px 7px;
  color: white;
  background-color: #00b399;
  font-weight: bold;
  cursor: pointer;
}

#overlay2 {
  visibility: hidden;
  position: absolute;
  left: 0px;
  top: 300px;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 1000;
  background-color: transparent !important;
}

div.-loading.-active {
  background: rgba(45, 52, 70, 0.74);
}

div.-loading.-active > div {
  color: white;
  font-size: 20px;
}

.fleft {
  float: right;
  font-size: 18px;
}

.flleft {
  font-size: 12px;
  color: #788195;
  float: right;
}

.tickb {
  font-weight: bold;
  padding: 1px 5px 1px 5px;
  color: rgb(80, 153, 255) !important;
  background-color: #000000;
}

/* .ant-modal-confirm-info {
  width: 60% !important;
} */
.ReactTable.-striped .rt-tr.-odd {
  border-bottom: 1px solid #212731;
  /* background-color: rgb(187 186 186 / 3%); */
}
.ReactTable.-striped .rt-tr {
  border-bottom: 1px solid #212731;
  /* background-color: rgb(187 186 186 / 3%); */
}
.rTable {
  background: rgb(25, 33, 38);
  color: white;
  margin-top: 12px;
}
.tsweeps {
  margin-bottom: 12px;
}
.tsweepsE {
  margin-bottom: 12px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(170px, 170px));
  justify-content: space-evenly;
}
.rTable .rt-thead {
  background-color: #303047;
  text-transform: uppercase;
}
#trade_contract {
  font-size: 16px;
  padding: 8px;
  background-color: rgb(33 46 60) !important;
  color: white;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 0px solid rgb(47, 61, 69);
}
.tableac {
  padding: 14px;
  font-size: 1.5em;
  color: #8a93c8;
}
.rt-tr.-odd {
  /* background: rgb(89 108 120 / 12%) !important; */
  /* background: rgb(20 29 38) !important;*/
  background: rgb(30 30 45 / 60%) !important;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 0px;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: black;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
  color: #ff2f64;
}

.hide {
  display: none;
}
input.adte {
  height: 34px;
}
#sType {
  height: 34px;
}
.adte {
  border: 0px;
  background-color: #28283e;
  color: white;
  margin-left: 3px;
  font-size: 14px;
}
.highcharts-button-box {
  fill: #303043 !important;
}
/* .strks{display:none;} */
.mIte {
  cursor: pointer;
  padding: 5px;
  /* background-color: #121d27; */
  background-color: #060d13;
  margin-right: 4px;
}

.SMenu {
  color: white;
  border-bottom: 5px solid #182025;
}
.titl {
  margin-top: 10px;
}
/* .highcharts-background {
  /* fill: #ffffff; */
/* stroke: #a4edba; */
/* stroke-width: 2px; 
} */

.goB {
  /* width: 34px; */
  height: 34px;
  margin-left: 4px;
  padding: 1px 10px 1px 10px;
  background-color: #2d3446;
}

#highvol {
  min-width: 310px;
  max-width: 100%;
  min-height: 600px;
  height: 800px;
  margin: 0 auto;
}

.CH,
.CA,
.OS,
.BT,
.OC {
  color: white;
  display: none;
}

.inpt {
  height: 22px;
}

.RowCH,
.RowOS,
.RowOC,
.RowBT,
.RowCA {
  padding: 7px;
  height: 100%;
}

/* .chrt {
  display:none; 
  } */
.sinfo {
  margin-left: 15px;
}

.vic {
  margin-top: 9px;
  margin-right: 8px;
  border: 1px solid #7252e6;
  background-color: white;
}

.ion-ios-flame-outline,
.squeezeflame {
  color: #d445ee !important;
  font-size: 15px;
}

/* .ant-spin-dot-item{color:red!important;background-color:red!important;} */
.ptr {
  cursor: pointer;
}

.tFri {
  background: #2d3446;
}

/* .isoStickerWidget {
  border-radius: 0px !important;
} */

.DayPickerInput input {
  font-size: 15px;
  height: 32px;
  padding-left: 5px;
  border-radius: 0px !important;
  border: 1px solid #e9e9e9;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 130px;
}

#Pane1 {
  min-width: 320px;
}

#root
  > div
  > div
  > div
  > section
  > section
  > section
  > main
  > div
  > div
  > div:nth-child(3)
  > div
  > div
  > div
  > h3 {
  margin-bottom: 0px;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgb(18, 103, 189);
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgb(114, 82, 230);
}

#Pane2 > div,
#Pane3 > div {
  min-height: 425px;
  min-width: 280px;
  max-height: 425px;
}

.search-wrapper {
  padding: 0px !important;
}

#Pane2 h3.isoBoxTitle span {
  color: white;
}

#Pane3 > div,
#Pane2 > div {
  padding: 10px;
  font-size: 13px;
  overflow-y: scroll;
  background-color: #152029;
  color: white;
  /* direction: rtl; */
}

#Pane3 > div > div:nth-child(1) > h3 {
  color: white;
}

h3.isoBoxTitle,
.isoExampleWrapper {
  direction: ltr;
}

.titl {
  font-weight: bold;
}

.ant-select-selection {
  margin-bottom: 3px;
}

.ion-ios-pulse {
  color: #faad14;
}

.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff;
  color: #4a90e2;
}

.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}

.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}

.InputFromTo-to .DayPickerInput-Overlay {
  /* margin-left: -198px; */
}

.margin10 {
  margin-bottom: 10px;
}

.pullRight {
  float: right;
}

.ant-badge-count {
  border-radius: 0px;
  padding: 2px !important;
  text-align: center;
}

.ant-badge-multiple-words {
  padding: 0 4px;
}

.fic {
  font-size: 16px;
  width: 18px;
  color: #7251e6;
}

.isoBoxWrapper {
  padding: 4px !important;
}

.ant-tabs-nav .ant-tabs-tab {
  padding: 4px;
}

input {
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
}

.bull {
  color: white;
  background-color: #00b399;
}
.bluish {
  background-color: #2d3447 !important;
}
.breakdown {
  background-color: #2d3447 !important;
}

.fmode {
  /* margin-bottom: 87px !important; */
  /* height: 82px !important;
  top: 66px !important; */
  margin-bottom: 48px !important;
  margin-left: 8px;
  margin-right: 8px;
}
.bear {
  color: white;
  background-color: #ff2f64;
}
.packedBdge {
  margin-bottom: 20px !important;
}
.bdge {
  margin-bottom: 48px !important;
  margin-left: 8px;
  margin-right: 8px;
}

.isoBadgeLink {
  margin-top: 5px;
  padding-left: 5px;
  padding-top: 3px;
  color: white;
  font-weight: bold;
}

.hide {
  display: none !important;
}

button.ant-btn {
  margin-right: 10px;
  height: 30px !important;
  padding: 0 7px !important;
}

button,
input {
  border-radius: 0px !important;
}

.ionbox .ion-flame {
  color: rgb(114, 82, 230) !important;
}

.ion-ios-analytics {
  font-size: 18px !important;
}

.ion-clipboard,
.ion-ios-flask {
  margin-left: 1px !important;
}

.ion-flame {
  margin-left: 3px !important;
}

.ion-arrow-graph-down-right {
  color: #f64e60 !important;
}

ion-icon {
  cursor: pointer;
}
.ion-speedometer {
  /* color: rgb(80 153 240) !important; */
}
.ico {
  margin-left: 5px;
  color: #bc68aa;
}

.ion-stats-bars {
  color: #573dc7 !important;
  /* color: #ffa800 !important; */
}

.isoIconWrapper .ion-arrow-graph-up-right {
  color: #00b399 !important;
}

.DayPickerInput-OverlayWrapper {
  z-index: 9000 !important;
}

.clickab {
  cursor: pointer;
}
.flowres {
  color: #ff2f64;
  font-size: 14px;
}
#footerBar {
  z-index: 1;
  width: 100%;
  height: 30px;
  position: absolute;
  top: 1px;
  right: 1px;
}
.ReactTable .rt-expander:after {
  border-top: 7px solid rgb(0 158 247);
}
.fromToinp {
  height: 34px;
}
.tradetime {
  height: 34px;
  background-color: #2d3446;
  color: white;
  padding: 2px;
  border: 0px;
  margin: 3px;
}
.Blockt {
  cursor: pointer;
  color: #1890ff !important;
}
.ant-tabs-nav-container-scrolling {
  padding-left: 9px !important;
}
.creatable-dropdown {
  margin-bottom: 1px;
}
#stkfilter > option {
  background-color: #151521;
}
.stkfilter,
.floatf {
  margin-right: 4px;
  border: 0px;
  padding: 4px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  background-color: grey;
  color: white;
  font-weight: bold;
  font-size: 12px;
}
.stkfilter {
  font-size: 11px;
  margin-top: 2px;
  float: left;
  /* width: 117px; */
}
.optfilter {
  width: 126px;
}
/* MOBILE */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .DayPicker {
    flex-direction: row;
    font-size: 1.5em;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    /* display: inline-block; */
  }

  #Pane3 {
    margin-top: 10px;
  }

  .InputFromTo .DayPicker-Day {
    border-radius: 0 !important;
    padding: 2px !important;
  }

  .DayPicker-Caption > div {
    font-size: 1em !important;
  }

  .DayPickerInput-Overlay {
    top: 12px;
    font-size: 12px !important;
    /* width: 400px !important; */
    left: auto !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    /* position: absolute !important; */
  }

  .InputFromTo-to .DayPickerInput-Overlay {
    margin-left: -112px !important;
  }

  .DayPicker-wrapper {
    position: static !important;
    flex-direction: row;
    -webkit-user-select: none;
            user-select: none;
    max-width: 100%;
    margin: 0;
    padding: 0;
    /* overflow: visible; */
    /* width: fit-content;  */
  }

  .ReactTable img {
    max-width: 100px !important;
    max-height: 100px !important;
  }

  .DayPicker-Months {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
  }

  .DayPicker-Month {
    display: table;
    margin: 0;
    padding: 0;
    margin-top: auto;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
            user-select: none;
  }
  .noMob {
    display: none !important;
  }
  .flowtext {
    font-size: 12px !important;
  }
  #Pane3 > div {
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 3px;
    padding-left: 3px;
  }

  #root
    > div
    > div
    > div
    > section
    > section
    > section
    > main
    > div
    > div
    > div:nth-child(4)
    > div {
    padding-top: 3px;
    padding-right: 0px;
    padding-left: 3px;
    padding-bottom: 25px;
  }

  #root
    > div
    > div
    > div
    > section
    > section
    > section
    > main
    > div
    > div
    > div:nth-child(5)
    > div {
    padding-top: 3px;
    padding-right: 3px;
    padding-left: 3px;
    padding-bottom: 3px;
  }

  #root
    > div
    > div
    > div
    > section
    > section
    > section
    > main
    > div
    > div
    > div:nth-child(4)
    > div
    > h3 {
    margin-bottom: 25px;
    margin-top: 15px;
    margin-left: 10px;
  }

  #Pane3 > div > div:nth-child(1) > h3 {
    margin-top: 15px;
    margin-left: 20px;
  }

  #root
    > div
    > div
    > div
    > section
    > section
    > section
    > main
    > div
    > div
    > div:nth-child(5)
    > div
    > h3 {
    margin-top: 15px;
    margin-left: 10px;
  }

  #root
    > div
    > div
    > div
    > section
    > section
    > section
    > main
    > div
    > div
    > div:nth-child(3)
    > div
    > div
    > div
    > h3 {
    margin-top: 15px;
    margin-left: 10px;
    margin-bottom: 25px;
  }

  #root
    > div
    > div
    > div
    > section
    > section
    > section
    > main
    > div
    > div
    > div:nth-child(3)
    > div {
    padding-left: 3px;
    padding-right: 3px;
    padding-top: 3px;
  }
}

/* CALENDAR OVERFLOW ON SCREENS ON INPUT "TO" DATE*/
.InputFromTo-to .DayPickerInput-Overlay {
  /* margin-left: -113px !important; */
  margin-left: 10px;
}

.lBox {
  display: block;
  height: 360px;
  margin-right: auto;
  margin-left: auto;
}

.animation {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 360px;
  height: 360px;
  position: relative;
}

.ion-android-send {
  color: #9d87ee;
}

ul.isoRight li i {
  font-size: 20px !important;
}
#OS {
  padding: 0px !important;
}
.ion-ios-cloud-download {
  color: #1890ff;
  float: right;
  padding: 3px;
}
.flgss {
  font-size: 11px;
  margin-right: 1px;
  padding: 1px 3px 1px 2px;
  color: hsl(0deg 64% 49%);

  font-weight: bold;
  border-radius: 1px;
  cursor: pointer;
}
.theER {
  color: hsl(0deg 64% 49%);
  border: 1px solid #ea494973 !important;
  background-color: #ff00003b;
}
.theUF {
  color: yellow;
  border: 1px solid #eac94973 !important;
  background-color: #ffdd003b;
}
.ocld {
  color: rgb(79 255 24) !important;
  border: 1px solid rgb(79 255 24) !important;
  background-color: #00800029;
  margin: 1px;
  margin-right: 3px;
  font-weight: bold;
  font-size: 10px;
  padding: 1px 2px 1px 2px;
  cursor: pointer;
}
.numbdg {
  font-size: 12px;
  color: #1890ff;
  background-color: black;
  font-weight: bold;
  border-radius: 2px;
  padding: 2px 2px 2px 2px;
  cursor: pointer;
}

input[type='checkbox']:checked + label {
  background: rgb(157 134 237);
  color: #ffffff;
}
input[type='checkbox']:checked + label:hover {
  background: rgba(0, 128, 128, 0, 0.7);
}
.presets {
  background-color: #f1f3f6;
  font-weight: 500;
  margin-bottom: 5px;
  padding: 2px;
  border-color: #e9e9e9;
}
.tikr {
  font-size: 12px;
}
.rightB {
  background: #f1f3f6;
  padding-left: 12px;
}
.hdthis,
.hist {
  display: none !important;
}

.ant-tabs {
  margin-bottom: 0px;
}

.ant-tabs-bar {
  border-bottom: 0px;
  margin: 0 0 7px 0 !important;
}

.ant-tabs-ink-bar {
  background-color: #7252e6 !important;
}

input {
  padding-left: 5px;
}

.ordr:hover {
  color: #7252e6;
}

.ordr {
  cursor: pointer;
  padding: 3px;
  background-color: #f1f3f6;
  /* font-weight: normal; */
  color: rgb(18 29 39);
  text-transform: uppercase;
  font-size: 12px;
  margin-left: 2px !important;
}
.hideThis {
  display: none !important;
}
.ant-layout-footer {
  padding: 5px 0px !important;
}

.ant-tabs-nav .ant-tabs-tab:hover {
  color: #9788d8 !important;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: #7252e6 !important;
}

.ant-btn-primary {
  background-color: #7252e6 !important;
}

.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: rgb(114, 82, 230) !important;
}

.ant-btn-primary,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  border-color: rgb(114, 82, 230);
  background-color: rgb(114, 82, 230);
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  font-size: 13px;
}

.ant-modal-confirm .ant-modal-body {
  padding: 6px 4px 4px !important;
}

.ant-modal-content {
  position: relative;
  background-color: #14202c !important;
}

.ant-modal-confirm-body
  > .anticon
  + .ant-modal-confirm-title
  + .ant-modal-confirm-content {
  margin-left: 0px !important;
}

.ant-modal-confirm .ant-modal-confirm-btns {
  margin-left: 0px !important;
}

.ttech {
  border: 1px solid #141d26;
  background-color: #151521 !important;
}

.ant-modal-confirm-body > .anticon {
  margin-right: 5px !important;
}

.ion-ios-flask:hover,
.ion-document:hover,
.ion-ios-analytics:hover,
.ion-ios-paper:hover,
.ion-ios-search:hover {
  opacity: 1;
}

.ion-document,
.ion-ios-paper,
.ion-ios-analytics.ptr,
.ion-ios-copy-outline {
  opacity: 0.7;
  font-size: 17px;
  vertical-align: bottom;
  color: rgb(31 150 243);
}

.ion-ios-flask.ptr {
  font-size: 12px;
  color: rgb(80, 153, 255);
}

.ptr {
  margin: 1px;
  vertical-align: initial !important;
}

.ion-ios-analytics.ptr {
  font-size: 14px !important;
}

.ion-ios-navigate-outline,
.ion-ios-analytics.ptr .ion-information-circled {
  font-size: 14px;
  color: rgb(114, 82, 230);
  /* color:#bb68a9; */
}

.ion-ios-copy-outline {
  padding: 3px;
  padding-right: 3px;
}

.ion-ios-search {
  opacity: 0.7;
  font-size: 17px;
  vertical-align: middle;
}

.ion-ios-plus-outline {
  font-size: 17px;
  margin-left: 5px;
  font-weight: bold;
  margin-top: 3px;
  color: #6cff51;
  opacity: 0.7;
}

.ion-ios-help-outline {
  color: rgb(114, 82, 230);
}

.ion-ios-close {
  color: #96a2c1;
}

.ion-ios-flask {
  font-size: 17px !important;
}

.ion-code,
.ion-code-download {
  color: rgb(70 70 106);
  margin-right: 9px;
}

#table-columns {
  /* display: inline-block !important; */
  /* column-count: 7; */
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
}

#mstretch {
  background-color: #1a1a27 !important;
  /* border: 0px solid; */
  border: 1px solid rgb(20 29 38);
}

.columns-names {
  display: inline-flex;
  width: 100px;
  flex-direction: row;
  margin: 0 20px 0 20px;
  align-items: center;
}

.columns-names input {
  display: inline;
  align-items: center;
}

@media only screen and (min-width: 992px) and (max-width: 1060px) {
  #Pane1,
  #Pane2,
  #Pane3 {
    padding-left: 0px;
    padding-right: 0px;
    width: 100% !important;
  }
}

#Pane1,
#Pane2,
#Pane3 {
  /* margin:1px; */
  margin-bottom: 6px;
  /* padding: 0px !important; */
}
@media only screen and (max-width: 700px) {
  #market-open-close {
    display: none !important;
  }
}
@media only screen and (max-width: 620px) {
  #marketState,
  .noMob {
    display: none !important;
  }
}
@media only screen and (max-width: 500px) {
  #Pane1,
  #Pane2,
  #Pane3 {
    margin-bottom: 30px !important;
  }
  .Tmode {
    top: 0px !important;
    position: relative;
  }
}
@media only screen and (min-width: 457px) and (max-width: 767px) {
  #Pane1 {
    height: 450px !important;
  }
}
signaltype {
  color: pink;
}
/* Ticker Box Component styles */
#tickerbox-loading {
  background: #152029;
}

/*Speech settings styles*/
.speech-setting-input-number {
  border-radius: 0px !important;
  font-size: 13px;
  width: 40px;
  border: 1px solid #121d27;
}

.speech-input-slider {
  padding: 0px !important;
}

.speech-input-slider .ant-slider-rail {
  background-color: #9b9b9b;
}

.speech-input-slider .ant-slider-track {
  background-color: rgb(124, 124, 124);
}

.speech-input-slider:hover .ant-slider-rail {
  background-color: #bbbbbb;
}

.speech-input-slider:hover .ant-slider-track {
  background-color: rgb(116, 116, 116);
}
.screenshare {
  font-size: 15px !important;
}
.hidethis {
  display: none !important;
}
/* InputInterval */
.stylebutton,
.stylebutton_color {
  color: rgb(163 136 209) !important;
}
#interval_input,
#depth_chart_option {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #102a38;
  background-image: none;
}
.ant-row.boxTop span {
  color: #788195;
}
/* Remove IE arrow */
#interval_input::-ms-expand,
#depth_chart_option::-ms-expand {
  display: none;
}
/* #interval_input {
  right: 2px;
} */
/* Custom Select */
#interval_input,
#depth_chart_option {
  position: relative;
  display: flex;
  background: #2c3e50;
  overflow: hidden;
}

#interval_input,
#depth_chart_option {
  flex: 1 1;
  /* padding: 0 10px; */
  padding-left: 7px;
  color: #fff;
  cursor: pointer;
}

/* Arrow */
#interval_input::after,
#depth_chart_option::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #34495e;
  cursor: pointer;
  pointer-events: none;
  transition: 0.25s all ease;
}

/* Transition */
#interval_input:hover::after,
#depth_chart_option:hover::after {
  color: #f39c12;
}
.signal_chart_xyz_box {
  background-color: #1e1e2d;
}
.signal_chart_xyz_box h3.isoBoxTitle {
  color: #485160 !important;
}

/* Clock widget */

#market-open-close {
  font-weight: 500;
  background: #1a1a27 !important;
  padding: 2px 2px;
  font-size: 12px;
  color: #000000;
}

/* Back Testing */
/* Backtest*/
.backtest__label {
  display: block;
  color: rgba(0, 0, 0, 0.6);
  margin: 3px;
  font-weight: bold;
  font-size: 11px;
}

.backtest__input {
  display: block;
  margin-bottom: 4px;
  width: 100% !important;
  color: rgba(0, 0, 0, 0.7);
  border-radius: 0 !important;
  height: 22px !important;
  font-weight: 400 !important;
  padding: 2px 5px !important;
}

.backtest__select {
  display: inline-block;
  padding: 2px 4px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
}

.backtest__aside__select {
  display: inline-block;
}
.backtest__button {
  outline: none;
  border: none;
  padding: 2px 8px;
  font-size: 13px;
  color: white;
  background-color: #788195;
  cursor: pointer;
}

#Pane2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

@media only screen and (min-width: 10px) and (max-width: 1060px) {
  #Pane2 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  #newsInputs {
    flex-wrap: wrap !important;
  }
}

::-webkit-scrollbar-thumb {
  /* -webkit-border-radius: 10px;
  border-radius: 10px; */
  background: #7c5adccc;
}

.rt-draggable-container .arrow .arrow:after,
.rt-draggable-container .arrow:after {
  border-color: #1890ff;
}
.rt-draggable-container .arrow .arrow:before,
.rt-draggable-container .arrow:before {
  background-color: #1890ff;
}
.ptrade {
  top: 3px;
  position: relative;
}
.hideComp {
  margin-top: 1px;
  margin: 0 5px;
  background-color: #d5cbf8;
  border-radius: 100%;
  padding: 2px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}
.hideComp svg path {
  fill: rgb(132, 107, 223);
}

.newEntry {
  color: #9e5a00 !important ;
}

.tfInputs input[type='radio'] {
  display: none;
}
.tfInputsUp input[type='radio'] {
  display: none;
}
.tfInputsUp input[type='radio']:checked + label {
  background: #343488 !important;
  border-color: #141d26 !important;
  color: #ffffff !important;
}

.tfInputs input[type='radio']:checked + label {
  border-color: #141d26 !important;
  background: rgb(157 134 237) !important;
  color: #ffffff !important;
  font-size: 1em;
}
.tfInputs input[type='radio'] + label {
  font-size: 1em;
}
.fillAsk {
  color: #7c63ec;
  border: dotted 1px #7c63ec;
  background-color: #2a1a558c;
  padding: 2px 4px;
  font-weight: 500;
}
.fillBid {
  color: #ffa45a;
  border: dotted 1px #ffa45a;
  background-color: #68370f98;
  padding: 2px 4px;
  font-weight: 500;
}
.fillmidpoint,
.fillMidpoint {
  color: #fc6289;
  border: dotted 1px #fc6289;
  background-color: #66081f8a;
  padding: 2px 4px;
  font-weight: 500;
}
.mNessATM {
  color: #9c9c9c;
  border: dotted 1px #9c9c9c;
  background-color: #3a3a3a8c;
  padding: 2px 4px;
  font-weight: 500;
}
.mNessITM {
  color: #727bf5;
  border: dotted 1px #727bf5;
  background-color: #0300a78a;
  padding: 2px 4px;
  font-weight: 500;
}
.mNessOTM {
  color: #50bb4d;
  border: dotted 1px #50bb4d;
  background-color: #0a52008a;
  padding: 2px 4px;
  font-weight: 500;
}

.rt-th {
  justify-content: start !important;
  padding-left: 0 !important;
}

.rt-draggable-container div.draggable-header.enable-drag {
  padding-left: 0 !important;
}

.tickerInput,
.tickerInput:hover {
  text-transform: uppercase;
  border: 1px solid #7252e6 !important;
  background-color: #121d27;
  color: #fff;
  font-size: 1rem;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  margin-right: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.tickerInput:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  color: #fff;
  background-color: #1b2a35;
  border-color: #7252e6;
}

.applyButton {
  border-radius: 5px !important;
  height: 30px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: #2d3446;
  font-size: 0.775rem;
  line-height: 1.5;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.applyButton:focus {
  color: #7252e6;
  border-color: #7252e6;
  background-color: transparent;
  box-shadow: 0 0 0 0.2rem rgb(114 82 230 / 37%);
}
.applyButton:active {
  color: #fff;
  background-color: #7861ce;
  border-color: #7861ce;
}
.optionsInp {
  display: flex;
  margin-left: 10px;
}
.optionsInp label {
  color: white;
  font-size: 12px;
  margin-left: 3px;
  margin-right: 3px;
}
.optionsInp input {
  margin-left: 15px;
}

.toolBar-tech li {
  background-color: #282835 !important;
}

.ant-badge-count {
  background-color: #ebebeb !important;
}
.ant-badge-count span {
  color: #222222;
}
.ant-badge-count .pcBadge {
  color: #0026ff;
}

.paneTitle {
  background-color: #1e1e2d;
}
.pset {
  color: white;
}

.ReactTable .rt-table {
  overflow-x: auto;
}
input[name='searchInput'] {
  height: 34px;
}
input[name='searchInput']::placeholder {
  color: white;
}
.infoContainer {
  width: 60%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 10px auto;
}

.infoLine {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px;
  font-size: 14px;
  border-bottom: 1px solid rgb(51, 51, 51);
}

.infoTitle {
  color: #9e9e9e;
}

.infoValue {
  color: #eeeeee;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.flexColCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.planCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  margin: 30px 10px;
  background-color: #dddddd;
  padding: 15px;
}

.planCont h1 {
  color: rgba(0, 0, 0, 0.8);
  font-size: 32px;
  font-weight: 600;
}

.planFeatures li {
  display: flex;
  margin: 10px 5px;
  align-items: center;
}
.planTitle p,
.planPrice p {
  color: rgb(95, 95, 95);
}
.planTitle {
  text-align: center;
}

.planTitle,
.planPrice {
  margin: 10px 5px;
  border-bottom: 1px solid rgb(167, 167, 167);
  width: 100%;
  padding: 10px;
}
.planbtn {
  cursor: pointer;
  background-color: #28263c;
  padding: 5px;
}
.bluet {
  color: #8a93c8 !important;
}
.planButton {
  border-radius: 9999999px !important;
  text-align: center;
  width: 100%;
  margin: 20px 5px;
  height: 40px;
  padding-top: 6px;
  background-color: #7252e6;
  color: white;
  font-size: 19px;
  font-weight: 600;
}
.upgMessage,
.saveTxt {
  color: rgb(19, 19, 19);
}

.earnTable .rt-th {
  text-align: left;
  margin-left: 5px;
}

.weekInputs input[type='checkbox']:checked + label {
  color: rgb(216 186 255) !important;
  border-bottom: 5px solid rgb(111, 0, 255) !important;
  background-color: rgba(156, 134, 237, 0.192) !important;
}

.weekInputs label {
  font-size: 16px;
  color: white !important;
  padding: 4px 8px;
  cursor: pointer;
}
.weekInputs label:hover {
  border-bottom: 2px solid rgb(111, 0, 255) !important;
}

.SocialW h4 {
  color: #ffffff;
}

.primaryColor {
  color: rgb(114, 82, 230);
}

.secondaryColor {
  /* color : #788195 */
  color: #9fabc5;
}

.bullishColor {
  color: #34c38f;
}
.FlowDetails {
  padding-left: 1px;
  padding: 2px;
  margin: 1px;
}
.bearishColor {
  color: #f46a6a;
}
.fcall {
  background-color: #0080002e;
}
.fput {
  background-color: #f64d5333;
}
button.apply {
  /* color: #7252e6 !important; */
}

.signalTradeCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: solid 1px rgb(114, 113, 113);
  color: white;
}

.signalTradeTitle {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #353535;
}

.signalTradeDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin: 20px auto;
}

.signalCard {
  padding: 20px 10px;
  color: white;
  cursor: pointer;
  border-bottom: 1px solid rgb(152, 152, 152);
}

.signalCard:hover {
  background-color: #7252e65e;
}

.signalCardSelected {
  background-color: #7252e65e;
}

.backTabs {
  display: flex;
  align-items: center;
}

.backTab {
  width: 180px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  margin: 5px 15px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
}

.backTab:hover {
  color: #9d8bdc;
  border-bottom: solid 1px #9d8bdc;
}

.newTestBtn {
  width: 180px;
  height: 50px;
  font-size: 22px;
  text-align: center;
  margin: 5px 15px;
  padding: 10px 20px;
  color: white;
  background-color: #7252e6;
}

.activBackTab {
  border-bottom: solid 1px #7252e6;
  color: #7252e6;
}
.activBackTab:hover {
  border-bottom: solid 1px #7252e6;
  color: #7252e6;
}

.backtestDrawer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #0000003d;
}

.drawerCont {
  float: right;
  width: 40%;
  background-color: white;
}

.drawerCont hr {
  background-color: #7252e6;
  height: 2px;
  margin: 20px auto;
}

.drawerEntry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  margin: 10px 5px;
}

.drawerEntry label {
  font-size: 16px;
  color: black;
  font-weight: normal;
}

.drawerEntry select {
  width: 40%;
  padding: 5px 10px;
  font-size: 16px;
  text-align: center;
}
.drawerEntry select:focus {
  border: 1px solid #7252e6;
}
.drawerEntry select:focus-visible {
  border: 1px solid #7252e6;
}
.backtestTicker {
  padding: 2px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  background-color: #7252e64b;
  color: #7252e6;
  margin: auto 4px;
}
.backtestStrategy {
  padding: 2px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  background-color: #f4f4f466;
  color: #000000;
  margin: auto 4px;
}

.addListForm {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  margin: 20px auto;
}

.formContainer {
  width: 30%;
  height: 60%;
  background-color: #292929;

  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regFormContainer {
  grid-gap: 2rem;
  gap: 2rem;
  width: 25rem;
  max-width: calc(100vw - 5rem);
  background-color: #292929;
  padding: 15px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 1px 5px 12px;
  border: 1px solid transparent;
}
.formContainer input,
.regFormContainer input {
  width: 100%;
}

.credentialInput {
  margin: 5px 3px;
  width: 200px;
  height: 35px;
  padding: auto 10px;
  background-color: rgb(83, 83, 83);
  color: white;
}

.credentialInput:focus,
.credentialInput:focus-visible {
  outline: 1px solid #7252e6;
  background-color: rgb(109, 109, 109);
}
.credentialInput:hover {
  outline: 1px solid #7252e6;
}

.loginButton {
  width: 80%;
  margin: auto 40px;
  color: 'white' !important;
  background-color: #7252e6;
  padding: 10px;
  font-size: 16px !important;
  text-align: center;
}
.css-1hb7zxy-IndicatorsContainer {
  align-self: start !important;
  position: sticky !important;
  top: 0;
  right: 0;
}

.analysisTextPositive {
  background-color: #00b39878;
  color: white;
}
.analysisTextPositive .Positive {
  background-color: #00b398;
  font-weight: bold;
}
.analysisTextNegative {
  background-color: #ff2f638a;
  color: white;
}
.analysisTextNegative .Negative {
  background-color: #ff2f63;
  font-weight: bold;
}

.analysisTextSpecific-FLS {
  background-color: #00b39878;
  color: white;
}
.analysisTextSpecific-FLS .Specific-FLS {
  background-color: #00b398;
  font-weight: bold;
}
.analysisTextNon-specific-FLS {
  background-color: #ff2f638a;
  color: white;
}
.analysisTextNon-specific-FLS .Non-specific-FLS {
  background-color: #ff2f63;
  font-weight: bold;
}

#root
  > div
  > div
  > div
  > section
  > section
  > section
  > main
  > div
  > div
  > div
  > div.rt-table
  > div.rt-thead.-header {
  box-shadow: none;
}
/* .ReactTable .rt-table {
  overflow-x: overlay;
} */
.ReactTable .rt-noData {
  padding: 5px;
  background: rgb(5 15 20);
  color: rgb(105 64 242);
}
.rt-thead {
  font-size: 13px;
}
.rTable {
  background: #141d26;
}
.inpm {
  width: 60px;
  height: 24px;
}
.tickbx {
  font-weight: 500;
}
.Scanner div.rt-thead {
  color: white;
  /* color: rgb(114, 82, 230); */
  text-transform: uppercase;
}
.Scanner .CALLS,
.BUY,
.Buy,
.call {
  padding: 2px 7px 2px 7px;
  color: #23d98f !important;
  background-color: #060d13;
}
.BLOCK_TRADES,
.tickbx,
.PRICE_SPIKES,
.HALT_RESUMES {
  padding: 2px 7px 2px 7px;
  background-color: #060d13;
}

.tickbx {
  color: #7c5adc;
}
.Scanner .fb {
  padding: 2px 3px 2px 3px;
  color: #df8fcd;
  background-color: #060d13;
  margin-left: 3px;
  font-size: 12px;
  text-transform: capitalize;
}
.Scanner .PUTS {
  color: #ff2f64;
  background-color: #060d13;
}
.pla {
  font-size: 22px;
  color: rgb(124 90 220);
}
.src {
  text-transform: uppercase;
  padding: 0px;
  background-color: #28273d;
  color: rgb(124 90 220);
  font-size: 1.1em !important;
  border: 0px !important;
  -webkit-border: 0px !important;
  -moz-border: 0px !important;
}
.dsds {
  padding: 7px !important;
  color: white;
  min-width: 770px !important;
  background: #060d13;
}

.studyC {
  /* overflow-x: auto; */
}
.trhead {
  background-color: #2d3446;
}
.ifco {
  color: #7250e6;
  position: absolute;
  right: 3px;
}
#frappeBarChart {
  padding: 6px;
  background-color: white;
}
.tbox {
  width: 70px;
  padding-left: 5px;
  margin-right: 2px;
}
.assets {
  background-color: rgb(0 0 0 / 85%);
  padding: 4x;
  color: white;
  border: 0px;
}
.sigstyle {
  color: rgb(114, 82, 230);
}
.utoh {
  background: #f1f3f6;
}
button.stylebutton {
  background-color: #f0f2f5;
}
button {
  cursor: pointer;
  padding: 3px;
  margin-left: 4px;
  background-color: #182025;
  color: rgb(114, 82, 230);
  font-size: 14px !important;
  border-radius: 0px !important;
  border: 0px !important;
  -webkit-border: 0px !important;
  -moz-border: 0px !important;
}
button.update {
  cursor: pointer;
  padding: 7px !important;
  font-weight: 500;
  margin-left: 4px;
  background-color: #060d13;
  color: #e6cd52;
  font-size: 14px !important;
  border-radius: 0 !important;
  border: 0 !important;
  -webkit-border: 0 !important;
  -moz-border: 0 !important;
  letter-spacing: 1px;
}
.scannerc h1 {
  /* background: #182025; */
}
.scannerc h1::before {
  margin: 0 6px 0 0 !important;
  width: 0px !important;
  height: 40px !important;
  background-color: #f3b96c !important;
}
.scannerc h1::after {
  height: 0px !important;
}
.Scanner {
  /* margin-top: 4px !important; */
  margin-top: 0px !important;
}
.isoComponentTitle {
  margin-bottom: 0px !important;
}
/* .rt-th:nth-of-type(11),
.rt-th:nth-of-type(13),
.rt-th:nth-of-type(10),
.rt-th:nth-of-type(12) {
  color: white;
  background-color: #0a60b0;
} */
@media only screen and (max-width: 767px) {
  .scannerc h1 {
    margin: 0px !important;
  }
}
.studyB {
  font-size: 20px;
  position: absolute;
  right: 10px;
  display: none;
}
.ibx {
  background-color: #060d13;
  padding: 2px 7px 2px 7px;
  color: rgb(120, 129, 149);
}
.ibxv {
  color: #1890ff;
}
.cNM {
  font-size: 16px;
  margin-left: 10px;
}
.titl {
  margin-left: 6px;
  font-size: 14px;
}
.goButton {
  padding: 1px 3px 1px 3px;
  background-color: #2d3446;
}
tr.or {
  /* border-bottom: dotted 1px rgb(47, 61, 69); */
  background: #182025;
  color: white;
}
table.tuictrl {
  width: 100%;
}
/* select {border: 1px solid #7252e6;
} */
.opc,
thead.ohr {
  color: #f3b97e;
  background: #182025;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
}
thead.pphr {
  color: #9b7855;
  background: #182025;
  text-transform: uppercase;
  font-weight: normal;
}

table.tuictrl th {
  color: #f3b97e;
}
table th {
  font-weight: normal;
}
input.pp,
.studyInp {
  background-color: #22262e;
  color: rgb(80, 153, 255);
  /* color:  rgb(80, 255, 144); */
  margin-left: 6px;
  height: 25px;
}
table.tuictrl tr {
  padding: 7px 5px;
}
table.stbl {
  width: 100%;
}
table.stbl td {
  padding: 5px;
}
thead.ohr {
  background-color: #2d3446;
}
table input,
table select {
  border: 0px;
}
table.stbl td.strik {
  font-weight: bold;
  padding: 2px 4px 2px 7px;
  color: #1890ff;
  background-color: #060d13;
}
.exph3 {
  margin-bottom: 3px;
  background-color: #060d13;
  color: white;
  border-top: 1px dotted #bc68aa;
  padding: 5px;
  overflow-x: auto;
}
.exph3 h3 {
  color: white;
}

.ddates {
  font-size: 16px;
  z-index: 1000;
  border: 0px;
  border-radius: 0px;
  position: absolute;
  height: 25px;
  right: 10px;
  padding: 2px;
  top: 9px;
}
.ddates:hover,
.ddate:active,
.ddate:focus {
  /* width: 230px; */
  position: absolute;
  height: 130px;
}

.colborder {
  width: 70px;
}

h3 {
  font-size: 15px;
  font-weight: 400;
}
#AutoRefresh {
  margin-right: 10px;
}

.chat form {
  background: #fff;
  padding: 3px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-color: #000;
  border-top-style: solid;
  border-top-width: 1px;
}
input#txt {
  color: white;
  padding: 7px;
  background-color: #22293c;
  width: 96%;
  height: 40px;
  margin-bottom: 2px;
  margin-right: 0.5%;
  border: none;
  margin-left: 2px;
}
button#snd {
  margin-bottom: 2px;
  width: 70px;
  background: rgb(24 32 37);
  margin-left: 2%;
}
.chat #messages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#messages li {
  padding: 5px 10px;
}
#messages li:nth-child(even) {
  background: #474e64;
}
#messages li:nth-child(odd) {
  background: #373e51;
}
.ctrld {
  bottom: 32px;
  position: relative;
  text-align: center;
}
.resultat {
  padding: 5px;
  color: white;
}

.labn {
  padding: 3px;
  background-color: #2d3446;
  font-size: 11px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #2d3c45;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #182025;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.study {
  height: 27px;
  width: 135px;
}

.D,
.A,
.Option.Exercise,
.Faa,
.G {
  padding: 2px 7px 2px 7px;
  color: rgb(114, 82, 230) !important;
  background-color: #060d13;
}

.Sale,
.put {
  padding: 2px 7px 2px 7px;
  color: #ff2f64 !important;
  background-color: #060d13;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.ReactTable .-pagination .-btn {
  color: rgb(114, 82, 230);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  color: rgb(114, 82, 230);
}

