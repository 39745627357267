.isoMiddle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.notificationIcon {
  background-color: rgb(114, 82, 230);
  height: 8px;
  width: 11px;
  border-radius: 5px;
}
.wwico {
  vertical-align: bottom;
}
.spS {
  flex-shrink: 0 !important;
}
.spS span {
  display: flex;
  align-items: center;
}
.spS a {
  display: flex;
}

.ant-select-auto-complete.ant-select .ant-input {
  /* background-color: #2d3446 !important; */
  color: white !important;
}
.customAutoComplete .ant-input,
.customAutoComplete .ant-input:focus {
  color: white !important;
}
.customAutoComplete .ant-input::placeholder {
  color: white !important;
}
.customAutoComplete .ant-select-selector {
  background-color: transparent !important;
  border: none !important;
}
.ant-select-dropdown {
  /* width: 400px!important;  */
  background-color: #2d3446 !important;
}
.ant-select-dropdown-menu-item {
  color: white !important;
  background-color: #3c3c52 !important;
}
