#root
  > div
  > div
  > div
  > section
  > section
  > section
  > main
  > div
  > div
  > div
  > div.rt-table
  > div.rt-thead.-header {
  box-shadow: none;
}
/* .ReactTable .rt-table {
  overflow-x: overlay;
} */
.ReactTable .rt-noData {
  padding: 5px;
  background: rgb(5 15 20);
  color: rgb(105 64 242);
}
.rt-thead {
  font-size: 13px;
}
.rTable {
  background: #141d26;
}
.inpm {
  width: 60px;
  height: 24px;
}
.tickbx {
  font-weight: 500;
}
.Scanner div.rt-thead {
  color: white;
  /* color: rgb(114, 82, 230); */
  text-transform: uppercase;
}
.Scanner .CALLS,
.BUY,
.Buy,
.call {
  padding: 2px 7px 2px 7px;
  color: #23d98f !important;
  background-color: #060d13;
}
.BLOCK_TRADES,
.tickbx,
.PRICE_SPIKES,
.HALT_RESUMES {
  padding: 2px 7px 2px 7px;
  background-color: #060d13;
}

.tickbx {
  color: #7c5adc;
}
.Scanner .fb {
  padding: 2px 3px 2px 3px;
  color: #df8fcd;
  background-color: #060d13;
  margin-left: 3px;
  font-size: 12px;
  text-transform: capitalize;
}
.Scanner .PUTS {
  color: #ff2f64;
  background-color: #060d13;
}
.pla {
  font-size: 22px;
  color: rgb(124 90 220);
}
.src {
  text-transform: uppercase;
  padding: 0px;
  background-color: #28273d;
  color: rgb(124 90 220);
  font-size: 1.1em !important;
  border: 0px !important;
  -webkit-border: 0px !important;
  -moz-border: 0px !important;
}
.dsds {
  padding: 7px !important;
  color: white;
  min-width: 770px !important;
  background: #060d13;
}

.studyC {
  /* overflow-x: auto; */
}
.trhead {
  background-color: #2d3446;
}
.ifco {
  color: #7250e6;
  position: absolute;
  right: 3px;
}
#frappeBarChart {
  padding: 6px;
  background-color: white;
}
.tbox {
  width: 70px;
  padding-left: 5px;
  margin-right: 2px;
}
.assets {
  background-color: rgb(0 0 0 / 85%);
  padding: 4x;
  color: white;
  border: 0px;
}
.sigstyle {
  color: rgb(114, 82, 230);
}
.utoh {
  background: #f1f3f6;
}
button.stylebutton {
  background-color: #f0f2f5;
}
button {
  cursor: pointer;
  padding: 3px;
  margin-left: 4px;
  background-color: #182025;
  color: rgb(114, 82, 230);
  font-size: 14px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  border-radius: 0px !important;
  border: 0px !important;
  -webkit-border: 0px !important;
  -moz-border: 0px !important;
}
button.update {
  cursor: pointer;
  padding: 7px !important;
  font-weight: 500;
  margin-left: 4px;
  background-color: #060d13;
  color: #e6cd52;
  font-size: 14px !important;
  border-radius: 0 !important;
  border: 0 !important;
  -webkit-border: 0 !important;
  -moz-border: 0 !important;
  letter-spacing: 1px;
}
.scannerc h1 {
  /* background: #182025; */
}
.scannerc h1::before {
  margin: 0 6px 0 0 !important;
  width: 0px !important;
  height: 40px !important;
  background-color: #f3b96c !important;
}
.scannerc h1::after {
  height: 0px !important;
}
.Scanner {
  /* margin-top: 4px !important; */
  margin-top: 0px !important;
}
.isoComponentTitle {
  margin-bottom: 0px !important;
}
/* .rt-th:nth-of-type(11),
.rt-th:nth-of-type(13),
.rt-th:nth-of-type(10),
.rt-th:nth-of-type(12) {
  color: white;
  background-color: #0a60b0;
} */
@media only screen and (max-width: 767px) {
  .scannerc h1 {
    margin: 0px !important;
  }
}
.studyB {
  font-size: 20px;
  position: absolute;
  right: 10px;
  display: none;
}
.ibx {
  background-color: #060d13;
  padding: 2px 7px 2px 7px;
  color: rgb(120, 129, 149);
}
.ibxv {
  color: #1890ff;
}
.cNM {
  font-size: 16px;
  margin-left: 10px;
}
.titl {
  margin-left: 6px;
  font-size: 14px;
}
.goButton {
  padding: 1px 3px 1px 3px;
  background-color: #2d3446;
}
tr.or {
  /* border-bottom: dotted 1px rgb(47, 61, 69); */
  background: #182025;
  color: white;
}
table.tuictrl {
  width: 100%;
}
/* select {border: 1px solid #7252e6;
} */
.opc,
thead.ohr {
  color: #f3b97e;
  background: #182025;
  text-transform: uppercase;
  font-weight: normal;
  font-size: 11px;
}
thead.pphr {
  color: #9b7855;
  background: #182025;
  text-transform: uppercase;
  font-weight: normal;
}

table.tuictrl th {
  color: #f3b97e;
}
table th {
  font-weight: normal;
}
input.pp,
.studyInp {
  background-color: #22262e;
  color: rgb(80, 153, 255);
  /* color:  rgb(80, 255, 144); */
  margin-left: 6px;
  height: 25px;
}
table.tuictrl tr {
  padding: 7px 5px;
}
table.stbl {
  width: 100%;
}
table.stbl td {
  padding: 5px;
}
thead.ohr {
  background-color: #2d3446;
}
table input,
table select {
  border: 0px;
}
table.stbl td.strik {
  font-weight: bold;
  padding: 2px 4px 2px 7px;
  color: #1890ff;
  background-color: #060d13;
}
.exph3 {
  margin-bottom: 3px;
  background-color: #060d13;
  color: white;
  border-top: 1px dotted #bc68aa;
  padding: 5px;
  overflow-x: auto;
}
.exph3 h3 {
  color: white;
}

.ddates {
  font-size: 16px;
  z-index: 1000;
  border: 0px;
  border-radius: 0px;
  position: absolute;
  height: 25px;
  right: 10px;
  padding: 2px;
  top: 9px;
}
.ddates:hover,
.ddate:active,
.ddate:focus {
  /* width: 230px; */
  position: absolute;
  height: 130px;
}

.colborder {
  width: 70px;
}

h3 {
  font-size: 15px;
  font-weight: 400;
}
#AutoRefresh {
  margin-right: 10px;
}

.chat form {
  background: #fff;
  padding: 3px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-color: #000;
  border-top-style: solid;
  border-top-width: 1px;
}
input#txt {
  color: white;
  padding: 7px;
  background-color: #22293c;
  width: 96%;
  height: 40px;
  margin-bottom: 2px;
  margin-right: 0.5%;
  border: none;
  margin-left: 2px;
}
button#snd {
  margin-bottom: 2px;
  width: 70px;
  background: rgb(24 32 37);
  margin-left: 2%;
}
.chat #messages {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
#messages li {
  padding: 5px 10px;
}
#messages li:nth-child(even) {
  background: #474e64;
}
#messages li:nth-child(odd) {
  background: #373e51;
}
.ctrld {
  bottom: 32px;
  position: relative;
  text-align: center;
}
.resultat {
  padding: 5px;
  color: white;
}

.labn {
  padding: 3px;
  background-color: #2d3446;
  font-size: 11px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #2d3c45;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #182025;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.study {
  height: 27px;
  width: 135px;
}

.D,
.A,
.Option.Exercise,
.Faa,
.G {
  padding: 2px 7px 2px 7px;
  color: rgb(114, 82, 230) !important;
  background-color: #060d13;
}

.Sale,
.put {
  padding: 2px 7px 2px 7px;
  color: #ff2f64 !important;
  background-color: #060d13;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.ReactTable .-pagination .-btn {
  color: rgb(114, 82, 230);
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  color: rgb(114, 82, 230);
}
